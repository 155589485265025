// noinspection JSUnusedGlobalSymbols
import React from 'react'
import '../styles/styles.scss'
import Index from '../components'
import { SEO } from '../components/seo'

const IndexZh = (props) => <Index location={props.location}/>

export default IndexZh

export const Head = () => (
  <SEO />
)
